<!--
 * @Author: Link
 * @Date: 2021-08-20 10:11:13
 * @LastEditTime: 2021-08-30 19:11:35
-->
<template>
  <div class="overview">
    <el-row>
      <overview-data :rowData="rowData" />
    </el-row>
    <el-row>
      <el-col :md="24" :lg="17" style="padding: 15px">
        <chart-panel @change="v => (shrink = v)" />
        <div
          class="statistics-container"
          :style="`max-height:${shrink ? '50vh' : '0'};margin-top:${shrink ? '30px' : 0}`"
        >
          <statistics :shrink="true" :rowData="rowData" />
        </div>
      </el-col>
      <el-col :md="24" :lg="7" style="padding: 15px" ref="right">
        <el-row>
          <el-col :md="11" :lg="24"><todo :data="todoData" /></el-col>
          <el-col :md="1" :lg="24"><div style="height: 30px"></div></el-col>
          <el-col :md="11" :lg="24"><side-panel /></el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="statistics-container" :style="`padding:15px;margin:0;max-height:${shrink ? '0' : '50vh'}`">
      <statistics :rowData="rowData" />
    </div>
  </div>
</template>

<script>
import OverviewData from './components/overviewData.vue'
import ChartPanel from './components/chartPanel.vue'
import SidePanel from './components/sidePanel.vue'
import Todo from './components/todo.vue'
import Statistics from './components/statistics.vue'
export default {
  components: { ChartPanel, SidePanel, OverviewData, Todo, Statistics },
  data() {
    return {
      shrink: false,
      overviewData: {},
      statisticsData: {},
      todoData: {},
      rowData: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/boom-center-statistics-service/sysAdmin/homestatistic/profileData').then(res => {
        this.rowData = res
      })
      // this.$api.mall.mall('getHomeStatistic').then(res => {
      //   res.homeTotalVO.totalUserCount = res.statisticsmemberVo.totalCount
      //   this.overviewData = res.homeTotalVO
      //   this.statisticsData = Object.assign(res.productVO, res.statisticsmemberVo)
      //   this.todoData = res.homeAffairVO
      // })
    }
  }
}
</script>

<style lang='less' scoped>
.overview {
  padding: 15px;
  background: #fafafb !important;
  .left {
    background: red;
  }
  .statistics-container {
    margin-top: 30px;
    transition: all 0.7s;
    overflow: hidden;
  }
}
</style>